import axios from "axios";
import React, { useState, createContext, useEffect } from "react";
import UseAPI from "../Token/token_refresh";

export const TypeMasterContext = createContext(null);

function TypeMasterContextProvider({ children }) {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  console.log(env_url);

  const [isTypeMasterLoading, setIsTypeMasterLoading] = useState(false);
  const [variant, setVariant ] = useState([])
  const [bomCategoryDropdown, setBomCategoryDropdown]= useState([])
  const [bomDepartmentDropdown, setBomDepartmentDropdown]= useState([])
  const [unitOfMeasurementDropdown, setUnitOfMeasurementDropdown]= useState([])
  const [procurementTypeDropdown, setProcurementTypeDropdown]= useState([])
  const [ materialTypeDropdown, setMaterialTypeDropdown ] = useState([])
  const [ materialSubTypeDropdown, setMaterialSubTypeDropdown ] = useState([])
  const [categoryDropdown, setCategoryDropdown ] = useState([])
  const [updateTypeDropdown, setUpdateTypeDropdown ] = useState([])
  const [transcationTypeDropdown, setTranscationTypeDropdown] = useState([])

  const getTypeMasterData = async () => {
    setIsTypeMasterLoading(true)
    let url = env_url + `/api/typemaster-dropdown/`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await api(config);
      let response = res.data.typemaster;
      console.log(res.data);
      setIsTypeMasterLoading(false);
      setVariant(response.filter((post) => post.category_value == "variant"))
      setBomCategoryDropdown(response.filter((post) => post.category_value == "bom category"))	
      setBomDepartmentDropdown(response.filter((post) => post.category_value == "bom department"))
      setUnitOfMeasurementDropdown(response.filter((post) => post.category_value == "unit of meaurement"))
      setProcurementTypeDropdown(response.filter((post) => post.category_value == "procurement_type"))
      setMaterialTypeDropdown(response.filter((post) => post.category_value == "material_type"))
      setMaterialSubTypeDropdown(response.filter((post) => post.category_value == "material_sub_type"))
      setCategoryDropdown(response.filter((post) => post.category_value == "bom category"))

      setUpdateTypeDropdown(response.update_type)
      setTranscationTypeDropdown(response.transcation_type)
    } catch (error) {
      console.log(error);
      setIsTypeMasterLoading(false);
    }
  };

  useEffect(() => {
    getTypeMasterData()
  }, [])

  return (
    <div>
      <TypeMasterContext.Provider
        value={{
          isTypeMasterLoading,
          variant,
          bomCategoryDropdown,
          bomDepartmentDropdown,
          unitOfMeasurementDropdown,
          procurementTypeDropdown,
          materialTypeDropdown,
          materialSubTypeDropdown,
          categoryDropdown,
          updateTypeDropdown,
          transcationTypeDropdown,
          getTypeMasterData
        }}
      >
        {children}
      </TypeMasterContext.Provider>
    </div>
  );
}

export default TypeMasterContextProvider;
