import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingContext } from "../../../Context/SettingContext";
import UseAPI from "../../../Token/token_refresh"
import secureLocalStorage from "react-secure-storage";

const EditPrivilege = () => {
  const userID = secureLocalStorage.getItem("userID")
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)
  // react Hook form Props
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false)

  const { getPrivilegesData } = useContext(SettingContext)

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var privilege = JSON.stringify({
      privilege_name: data.privilege_name,
      privilege_value: data.privilege_value,
      description: data.description,
      // updated_by: userID,
    });

    const url = env_url + `/api/privileges/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: privilege,
    };

    setSubmitLoading(true)
    api(config).then((response) => {
      console.log(JSON.stringify(response.data));
      Swal.fire({
        title: "Privilege Updated!",
        text: "The privilege details have been successfully updated.",
        icon: "success"
      });
      setSubmitLoading(false);
      getPrivilegesData(0,5)
      setTimeout(() => {navigate(-1)}, 2000);
      reset();
    }).catch((error) => {
      Swal.fire({
        title: "Error Updating Privilege",
        text: "There was an issue updating the privilege details. Please try again.",
        icon: "error"
      });
      setSubmitLoading(false);
      console.log(error);
    })
  };

  // Pre Population of Particular Product details 
  useEffect(() => {
    const editPrivilege = location.state.editpost;
    // defaultValues is a react form hook. 
    if (editPrivilege) {
      var defaultValues = {};
      defaultValues.privilege_name = editPrivilege.privilege_name;
      defaultValues.privilege_value = editPrivilege.privilege_value;
      defaultValues.description = editPrivilege.description;

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Privilege </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Privilege Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter privilege display name"
                    id="text-forms-privilege_name"
                    {...register("privilege_name", { required: true })}
                  />
                  {errors.privilege_name && (
                    <span className="fs-8 text-danger">
                      Privilege Display Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Privilege Name
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    placeholder="Enter privilege name"
                    id="email-forms-privilege_value"
                    {...register("privilege_value")}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Description<span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter description for privilege"
                    id="textarea-forms-description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="fs-8 text-danger">
                      Description is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-privilege-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-privilege"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPrivilege;
