import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const LogoutContext = createContext(null);

function LogoutContextProvider({ children }) {
  const [user, setUser] = useState([]);
  console.log(user)
  const env_url = process.env.REACT_APP_API_URL;
  console.log(env_url);

  // SPECIAL CASE:
  const api = axios.create({
    baseURL: env_url.url,
  });

  // Request interceptor to add token to the headers
  api.interceptors.request.use(
    async (config) => {
      const token = secureLocalStorage.getItem("accessToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor to handle token expiration
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // const { logoutHandler } = useContext(UsersContext);

      const originalRequest = error.config;

      // If access token is expired, handle it by refreshing
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark the request as retried
        const refresh = secureLocalStorage.getItem("refreshToken");

        if (refresh) {
          try {
            // Attempt to refresh the access token
            const response = await axios.post(
              `${env_url.url}/auth/token/refresh/`,
              {
                refresh: refresh,
              }
            );
            const { access } = response.data;

            // Store the new access token
            secureLocalStorage.setItem("accessToken", access);

            // Update the Authorization header and retry the original request
            originalRequest.headers["Authorization"] = `Bearer ${access}`;
            return api(originalRequest); // Retry the original request
          } catch (refreshError) {
            console.error("Token refresh failed:", refreshError);
            // Logout the user
            debugger;
            logoutHandler();
          }
        }
      }

      return Promise.reject(error);
    }
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const logoutHandler = () => {
    secureLocalStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
  };

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    logoutHandler,
    setUser
  };
  return (
    <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>
  );
}

export default LogoutContextProvider;
