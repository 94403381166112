import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import Select from "react-select";
import { BOMContext } from "../../Context/BOMContext";
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../Context/GenericContext";
import { TypeMasterContext } from "../../Context/TypeMasterContext";

const EditBOM = () => {
  const userID = secureLocalStorage.getItem("userID")
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const { getBOMData } = useContext(BOMContext);
  const { productDropdown, itemDropdown, supplierDropdown} = useContext(GenericContext)
  const { variant, bomCategoryDropdown, bomDepartmentDropdown, unitOfMeasurementDropdown } = useContext(TypeMasterContext)

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var bom = JSON.stringify({
      product_id: data.product_id.value,
      variant: data.variant.value,
      no_shades: data.no_shades,
      item_id: data.item_id.value,
      category: data.category.value,
      department: data.department.value,
      req_quan_of_item: data.req_quan_of_item,
      item_per_product: data.item_per_product,
      unit_of_measurement: data.unit_of_measurement.value,
      // supplier_id: data.supplier_id.value,
      per_no_of_shades : true,
      bom_code : location.state.editpost.bom_code
      // updated_by: userID,
    });

    const url = env_url + `/api/bom/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: bom,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "BOM Updated!",
          text: "The bom details have been successfully updated.",
          icon: "success",
        });
        setSubmitLoading(false);
        getBOMData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Updating Bom",
          text: "There was an issue updating the bom details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editBOM = location.state.editpost;
    // defaultValues is a react form hook.
    if (editBOM) {
      var defaultValues = {};
      defaultValues.no_shades = editBOM.no_shades;
      defaultValues.req_quan_of_item = editBOM.req_quan_of_item;
      defaultValues.item_per_product = editBOM.item_per_product;
      defaultValues.bom_code = editBOM.bom_code;

      const getProduct = {
        value: editBOM.product?.id,
        label: editBOM.product?.item_name,
      };
      defaultValues.product_id = getProduct;

      const getVariantType = {
        value: editBOM.variant,
        label: editBOM.variant,
      };
      defaultValues.variant = getVariantType;

      const getItem = {
        value: editBOM.item?.id,
        label: editBOM.item?.item_name,
      };
      defaultValues.item_id = getItem;

      const getCategory = {
        value: editBOM.category,
        label: editBOM.category,
      };
      defaultValues.category = getCategory;

      const getDepartment = {
        value: editBOM.department,
        label: editBOM.department,
      };
      defaultValues.department = getDepartment;

      const getMeaurement = {
        value: editBOM.unit_of_measurement,
        label: editBOM.unit_of_measurement,
      };
      defaultValues.unit_of_measurement = getMeaurement;

      // const getSupplier = {
      //   value: editBOM.supplier?.id,
      //   label: editBOM.supplier?.supplier_name,
      // };
      // defaultValues.supplier_id = getSupplier;

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit BOM </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Product<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={productDropdown.map((api) => {
                          return {label: api.product_name, value : api.id}
                        })}
                        isClearable={true}
                        id="select-forms-product_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="product_id"
                    control={control}
                  />
                  {errors.product_id && (
                    <span className="fs-8 text-danger">
                      Product is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Variant<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={variant.map((api) => {
                          return {value :api.name, label:api.name}
                        } )}
                        isClearable={true}
                        id="select-forms-variant"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="variant"
                    control={control}
                  />
                  {errors.variant && (
                    <span className="fs-8 text-danger">
                      Variant is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    No of Shades<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter no of shades"
                    id="email-forms-no_shades"
                    {...register("no_shades", { required: true })}
                  />
                  {errors.no_shades && (
                    <span className="fs-8 text-danger">
                      No of Shades is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={itemDropdown.map((api) => {
                          return {value :api.id, label:api.product_name}
                        } )}
                        isClearable={true}
                        id="select-forms-item_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Category<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={bomCategoryDropdown.map((api) => {
                          return {value :api.name, label:api.name}
                        } )}
                        isClearable={true}
                        id="select-forms-category"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Department<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={bomDepartmentDropdown.map((api) => {
                          return {value :api.name, label:api.name}
                        } )}
                        isClearable={true}
                        id="select-forms-department"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="department"
                    control={control}
                  />
                  {errors.department && (
                    <span className="fs-8 text-danger">
                      Department is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Request Quantity of Item<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter request quantity of item"
                    id="email-forms-req_quan_of_item"
                    {...register("req_quan_of_item", { required: true })}
                  />
                  {errors.req_quan_of_item && (
                    <span className="fs-8 text-danger">
                      Request Quantity of Item is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item Per Product<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter item per product"
                    id="email-forms-item_per_product"
                    {...register("item_per_product", { required: true })}
                  />
                  {errors.item_per_product && (
                    <span className="fs-8 text-danger">
                      Item Per Product is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Unit of Meaurement<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={unitOfMeasurementDropdown.map((api) => {
                          return {value :api.name, label:api.name}
                        } )}
                        isClearable={true}
                        id="select-forms-unit_of_measurement"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="unit_of_measurement"
                    control={control}
                  />
                  {errors.unit_of_measurement && (
                    <span className="fs-8 text-danger">
                      Unit of Meaurement is required
                    </span>
                  )}
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Supplier Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={supplierDropdown.map((api) => {
                          return {value : api.id, label: api.supplier_name}
                        })}
                        isClearable={true}
                        id="select-forms-supplier_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="supplier_id"
                    control={control}
                  />
                  {errors.supplier_id && (
                    <span className="fs-8 text-danger">
                      Supplier Name is required
                    </span>
                  )}
                </div> */}

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-bom-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-bom"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBOM;
