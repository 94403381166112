import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SettingContext } from "../../../Context/SettingContext";
import UseAPI from "../../../Token/token_refresh"
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "name",
    header: "Type Master Display Name",
  },
  {
    accessor: "value",
    header: "Type Master Name",
  },
  {
    accessor: "category",
    header: "Type Master Category",
    cell: (row) => <>{row?.category?.category_name}</>,
  },
  {
    accessor: "description",
    header: "Description",
  },
];

const TypeMaster = () => {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const [typeMasterEntries, setTypeMasterEntries] = useState(5);
  const [typeMasterPageIndex, setTypeMasterPageIndex] = useState(0);

  const {
    isTypeMasterLoading,
    rawTypeMasterDetails,
    getTypeMasterData,
    typeMasterTotalCount,
  } = useContext(SettingContext);

  const handleGlobalFilterChange = (value) => {
    console.log(value);
    getTypeMasterData(0, typeMasterEntries, value);
  };

  const handlePagination = (value) => {
    console.log(value);
    getTypeMasterData(value.pageIndex, value.pageSize);
    setTypeMasterPageIndex(value.pageIndex);
    setTypeMasterEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-type-master", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTypeMasterEntry(entry_id);
      }
    });
  };

  const deleteTypeMasterEntry = (id) => {
    const url = env_url + `/api/type-master/${id}/`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
    };

    api(config)
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          title: "Entry Deleted!",
          text: "The selected entry have been successfully deleted.",
          icon: "success",
        });
        getTypeMasterData(0, 5);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Entry not Deleted!",
          text: "The selected entry has not been successfully deleted.",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    getTypeMasterData(typeMasterPageIndex, typeMasterEntries);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Type Master</h3>
            {isTypeMasterLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content">
            <NavLink
              to="/create-type-master"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-typemaster"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-type-master"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-typemaster"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create Type Master</span>
            </NavLink>
          </div>
        </div>
      </div>

      <Table
        data={rawTypeMasterDetails}
        columns={columns}
        pagination
        pageIndex={typeMasterPageIndex}
        pageSize={typeMasterEntries}
        rowCount={typeMasterTotalCount}
        globalSearch
        editRows
        deleteRows
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default TypeMaster;
