import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UseAPI from "../../Token/token_refresh"
import Swal from "sweetalert2";
import { BOContext } from "../../Context/BOContext";

const columns = [
  {
    accessor: "item",
    header: "Sales Order number",
    cell: (row) => <>{row?.item?.item_name}</>,
  },
  {
    accessor: "work_order_req_quantity",
    header: "Work Order Request Quantity",
  },
  {
    accessor: "bom_id",
    header: "BOM code",
    cell: (row) => <>{row?.bom?.bom_code}</>,
  },
  {
    accessor: "work_order",
    header: "Work Order Id",
    cell: (row) => <>{row?.work_order?.work_order_code}</>,
  },
  {
    accessor: "supplier_id",
    header: "Supplier Name",
    cell: (row) => <>{row?.supplier?.supplier_name}</>,
  }
];

const BO = () => {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const [boEntries, setBOEntries] = useState(5);
  const [boPageIndex, setBOPageIndex] = useState(0);

  const {
    isBOLoading,
    rawBODetails,
    boTotalCount,
    getBOData,
  } = useContext(BOContext);

  const handleGlobalFilterChange = (value) => {
    console.log(value);
    getBOData(0, boEntries, value);
  };

  const handlePagination = (value) => {
    console.log(value);
    getBOData(value.pageIndex, value.pageSize);
    setBOPageIndex(value.pageIndex);
    setBOEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-bo", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBOEntry(entry_id);
      }
    });
  };

  const deleteBOEntry = (id) => {
    const url = env_url + `/api/bo/${id}/`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
    };

    api(config).then((response) => {
      console.log(response.data);
      Swal.fire({
        title: "Entry Deleted!",
        text: "The selected entry have been successfully deleted.",
        icon: "success"
      });
      getBOData(0,5)
    }).catch((error) => {
      console.log(error);
      Swal.fire({
        title: "Entry not Deleted!",
        text: "The selected entry has not been successfully deleted.",
        icon: "error"
      });
    });
  };

  useEffect(() => {
    getBOData(boPageIndex,boEntries);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">BO</h3>
            {isBOLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content">
            <NavLink
              to="/create-bo"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-bo"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-bo"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-bo"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create BO</span>
            </NavLink>
          </div>
        </div>
      </div>

      <Table
        data={rawBODetails}
        columns={columns}
        pagination
        pageIndex={boPageIndex}
        pageSize={boEntries}
        rowCount={boTotalCount}
        globalSearch
        editRows
        deleteRows
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default BO;
