import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import mock_data from "../../Data/MOCK_DATA.json";

const DefaultWorkOrder = () => {
  const env_url = process.env.REACT_APP_API_URL;
  const api = UseAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const item_details = location.state.editpost;
  console.log(item_details)

  const [workOrder, setWorkOrder] = useState([]);
  console.log(workOrder)

  const allottedQuantity = item_details?.to_be_produced;
  const targetQuantity = item_details?.to_be_produced;

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workOrders: [
        {
          target_quantity: "",
          prod_start_date: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "workOrders",
  });

  const [isSplit, setIsSplit] = useState(false);

  // Watch workOrders for real-time validation
  const workOrders = watch("workOrders");
  console.log(workOrders);

  // Function to handle the split logic
  const handleSplit = (index) => {
    // Check if the array is empty before splitting
    if (fields.length === 0) {
      const halfQuantity = Math.floor(allottedQuantity / 2);
      if (halfQuantity > 0) {
        // Update the current entry and add a new one
        setValue(`workOrders.${index}.target_quantity`, halfQuantity);
        append({
          target_quantity: halfQuantity,
          prod_start_date: item_details?.to_be_produced,
        });
        setIsSplit(true);
        return;
      }
    }

    const currentEntry = fields[index];
    console.log(currentEntry);
    const firstHalf = Math.floor(currentEntry.target_quantity / 2);
    const secondHalf = currentEntry.target_quantity - firstHalf;

    if (firstHalf > 0) {
      // Update the current entry and add a new one
      setValue(`workOrders.${index}.target_quantity`, firstHalf);
      append({
        target_quantity: secondHalf,
        prod_start_date: currentEntry.prod_start_date,
      });

      // Set the split state to true after the first split
      setIsSplit(true);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    console.log(data);
    var bulk_work_order = JSON.stringify(
      data?.workOrders?.map((api) => {
        // const date = api.targetDate // DD/MM/YYYY format
        // const [day, month, year] = date.split("-"); // Split the string
        // const formattedDate = `${year}-${month}-${day}`; // Rearrange and format
        return {
          id : api.id == "" ? null : api.id,
          sales_order_id: location.state.editpost.id,
          prod_start_date: api.prod_start_date,
          dispatch_date: api.prod_start_date,
          target_quantity: api.target_quantity,
        };
      })
    );

    console.log(bulk_work_order);

    const url = env_url + "/api/work-order/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: bulk_work_order,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Work Order Added!",
          text: "The work order details have been successfully added.",
          icon: "success",
        });
        setSubmitLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Adding Work Order",
          text: "There was an issue adding the work order details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  const getWorkOrderDetails = async () => {
    try {
      const res = await api.get(
        env_url +
          `/api/work-order/?sales_order_id=${location.state.editpost.id}`
      );
      console.log(res.data.results?.data);
      setWorkOrder(res.data.results?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const storedData = workOrder
    if (storedData) {
      setIsSplit(true);
      console.log("Updating form values with workOrder:", workOrder);
      var defaultValues = {};
      // Constructing defaultValues for the form
      defaultValues.workOrders = storedData;
      // Resetting the form with new default values
      reset(defaultValues);
    }
  }, [workOrder, reset]);

  useEffect(() => {
    getWorkOrderDetails();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-inner">
          <div className="nk-block">
            <div className="profile-ud-list">
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Item Name</span>
                  <span className="profile-ud-value">
                    {item_details?.product?.item_name}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Client Name</span>
                  <span className="profile-ud-value">
                    {item_details?.client?.client_name}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Sales order</span>
                  <span className="profile-ud-value">
                    {item_details?.so_number}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Product Code</span>
                  <span className="profile-ud-value">
                    {item_details?.product?.item_code}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Total Quantity</span>
                  <span className="profile-ud-value">
                    {" "}
                    {item_details?.quantity}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">BOM Code</span>
                  <span className="profile-ud-value"> {workOrder[0]?.work_order_code} </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Location</span>
                  <span className="profile-ud-value">
                    {item_details?.dispatch_location}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Target Date</span>
                  <span className="profile-ud-value">
                    {item_details?.target_date}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Work Order</span>
                  <span className="profile-ud-value">{workOrder[0]?.work_order_code}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <button
                    type="button"
                    onClick={() => handleSplit(0)}
                    className="btn btn-outline-primary"
                  >
                    <em className="icon ni ni-plus"></em>
                    <span>Split Work Order</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="profile-ud-list">
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Target Quantity</span>
                  <span className="profile-ud-value">{targetQuantity}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Alloted Quantity</span>
                  <span className="profile-ud-value">{allottedQuantity}</span>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* If it's the first split, show only one button */}
            {isSplit &&
              // Show the dynamic list after the first split
              fields.map((field, index) => (
                <div className="card my-2" key={field.id}>
                  <div className="card-inner">
                    <div className="row g-4">
                      <div className="form-group" style={{ display: "none" }}>
                        <input
                          {...register(`workOrders.${index}.id`)}
                          className="form-control"
                          type="text"
                          hidden
                        />
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">Target Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          {...register(`workOrders.${index}.target_quantity`, {
                            valueAsNumber: true,
                            validate: (value) => {
                              const totalTargetQuantity = workOrders.reduce(
                                (sum, item, i) =>
                                  sum +
                                  (i === index
                                    ? value
                                    : Number(item.target_quantity)),
                                0
                              );

                              // Calculate the allowed limit (5% more than allottedQuantity)
                              const allowedLimit = allottedQuantity * 1.05; // 105% of allottedQuantity

                              // Check if the totalTargetQuantity exceeds the allowed limit
                              const isValid =
                                totalTargetQuantity <= allowedLimit;
                              if (!isValid) {
                                // Trigger SweetAlert if validation fails
                                Swal.fire({
                                  icon: "error",
                                  title: "Validation Error",
                                  text: `Total target quantity exceeds the allowed 5% tolerance (${allowedLimit.toFixed(
                                    2
                                  )}).`,
                                  confirmButtonText: "OK",
                                });
                              }

                              return isValid; // Return true or false for validation
                            },
                          })}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">Target Date</label>
                        <input
                          type="date"
                          className="form-control"
                          {...register(`workOrders.${index}.prod_start_date`)}
                        />
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-12">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => remove(index)}
                        >
                          Delete
                        </button>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-success"
                          onClick={() => handleSplit(index)}
                        >
                          Split Work Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {isSplit && (
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ marginLeft: "10px" }}
                >
                  Save
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default DefaultWorkOrder;
