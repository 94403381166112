import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BOMContext } from "../../Context/BOMContext";
import UseAPI from "../../Token/token_refresh"
import Swal from "sweetalert2";
import { GenericContext } from "../../Context/GenericContext";
import { TypeMasterContext } from "../../Context/TypeMasterContext";

const columns = [
  {
    accessor: "product",
    header: "Product Name",
    cell: (row) => <>{row?.product?.item_name}</>
  },
  {
    accessor: "variant",
    header: "Variant",
  },
  {
    accessor: "no_shades",
    header: "No of Shades",
  },
  {
    accessor: "item",
    header: "Item Name",
    cell: (row) => <>{row?.item?.item_name}</>
  },
  {
    accessor: "category",
    header: "Category",
  },
  {
    accessor: "department",
    header: "Department",
  },
  {
    accessor: "item_per_product",
    header: "Item Per Product",
  },
  {
    accessor: "req_quan_of_item",
    header: "Request Quantity Item",
  },
  {
    accessor : "unit_of_measurement",
    header : "Unit Measurement"
  }
];

const BOM = () => {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const [bomEntries, setBOMEntries] = useState(5);
  const [bomPageIndex, setBOMPageIndex] = useState(0);

  const {getGenericData} = useContext(GenericContext)
  const {getTypeMasterData} = useContext(TypeMasterContext)

  const {
    isBOMLoading,
    rawBOMDetails,
    bomTotalCount,
    getBOMData,
  } = useContext(BOMContext);

  const handleGlobalFilterChange = (value) => {
    console.log(value);
    getBOMData(0, bomEntries, value);
  };

  const handlePagination = (value) => {
    console.log(value);
    getBOMData(value.pageIndex, value.pageSize);
    setBOMPageIndex(value.pageIndex);
    setBOMEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-bom", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBOMEntry(entry_id);
      }
    });
  };

  const deleteBOMEntry = (id) => {
    const url = env_url + `/api/bom/${id}/`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
    };

    api(config).then((response) => {
      console.log(response.data);
      Swal.fire({
        title: "Entry Deleted!",
        text: "The selected entry have been successfully deleted.",
        icon: "success"
      });
      getBOMData(0, 5)
    }).catch((error) => {
      console.log(error);
      Swal.fire({
        title: "Entry not Deleted!",
        text: "The selected entry has not been successfully deleted.",
        icon: "error"
      });
    });
  };

  useEffect(() => {
    getBOMData(bomPageIndex,bomEntries);
  },[]);

  useEffect(() => {
    getGenericData()
    getTypeMasterData()
  }, [])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">BOM</h3>
            {isBOMLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content">
            <NavLink
              to="/create-bom"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-client"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-bom"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-client"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create BOM</span>
            </NavLink>
          </div>
        </div>
      </div>

      <Table
        data={rawBOMDetails}
        columns={columns}
        pagination
        pageIndex={bomPageIndex}
        pageSize={bomEntries}
        rowCount={bomTotalCount}
        globalSearch
        editRows
        deleteRows
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default BOM;
