import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import UseAPI from "../../Token/token_refresh";
import { ClientContext } from "../../Context/ClientContext";
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../Context/GenericContext";

const EditClient = () => {
  const userID = secureLocalStorage.getItem("userID");
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const { getClientData } = useContext(ClientContext);
  const { user } = useContext(GenericContext);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    let client = new FormData();
    client.append("client_name", data.client_name);
    client.append("client_location", data.client_location);
    client.append("gst_no", data.gst_no);
    client.append("gst_cert", data.gst_cert);
    client.append("mob_no", data.mob_no);
    client.append("email", data.email);
    client.append("user_id", data.user.value);

    for (var pair of client.entries()) {
      console.log(pair);
    }

    const url = env_url + `/api/client/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      data: client,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Client Updated!",
          text: "The client details have been successfully updated.",
          icon: "success",
        });
        setSubmitLoading(false);
        getClientData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Updating Client",
          text: "There was an issue updating the client details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editClient = location.state.editpost;
    // defaultValues is a react form hook.
    if (editClient) {
      var defaultValues = {};
      defaultValues.client_name = editClient.client_name;
      defaultValues.client_location = editClient.client_location;
      defaultValues.gst_no = editClient.gst_no;
      defaultValues.email = editClient.email;
      defaultValues.mob_no = editClient.mob_no.slice(3);

      const getUser = {
        value: editClient.user?.id,
        label: editClient.user?.first_name + " " + editClient.user?.last_name,
      };
      defaultValues.user = getUser;

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Client </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Client Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter client name"
                    id="text-forms-client_name"
                    {...register("client_name", { required: true })}
                  />
                  {errors.client_name && (
                    <span className="fs-8 text-danger">
                      Client Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Client Location<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter client location"
                    id="email-forms-client_location"
                    {...register("client_location", { required: true })}
                  />
                  {errors.client_location && (
                    <span className="fs-8 text-danger">
                      Client Location is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    GST No.<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter gst no"
                    id="email-forms-gst_no"
                    {...register("gst_no", { required: true })}
                  />
                  {errors.gst_no && (
                    <span className="fs-8 text-danger">GST no is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">
                      GST Certificate{" "}
                      {location.state.editpost.gst_cert ? (
                        <span></span>
                      ) : (
                        <span className="fs-8 text-danger"> *</span>
                      )}
                    </label>
                    {location.state.editpost.gst_cert ? (
                      <a
                        href={location.state.editpost.gst_cert}
                        className="btn btn-xs btn-primary"
                      >
                        <em className="icon ni ni-eye"></em>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <Controller
                    name="gst_cert"
                    control={control}
                    rules={{
                      required: location.state.editpost.gst_cert ? false : true,
                      // validate: (value) => {
                      //   const fileType = value?.[1]?.type;
                      //   return (
                      //     fileType === "application/pdf"
                      //   );
                      // },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-gst_cert"
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files[0]);
                        }}
                      />
                    )}
                  />
                  {errors.gst_cert && (
                    <span className="fs-8 text-danger">
                      GST Certificate is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Mobile Number<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter mobile number"
                    id="email-forms-mob_no"
                    {...register("mob_no", {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                      validate: {
                        startsWithValidDigit: (value) =>
                          ["9", "8", "7", "6"].includes(
                            value.toString().charAt(0)
                          ) || "First digit should be 9, 8, 7, or 6",
                      },
                    })}
                  />
                  {errors.mob_no && errors.mob_no.type === "required" && (
                    <span className="fs-8 text-danger">
                      Mobile number is required
                    </span>
                  )}
                  {errors.mob_no && errors.mob_no.type === "minLength" && (
                    <span className="fs-8 text-danger">
                      Mobile number should be at least 10 digits
                    </span>
                  )}
                  {errors.mob_no && errors.mob_no.type === "maxLength" && (
                    <span className="fs-8 text-danger">
                      Mobile number should not exceed 10 digits
                    </span>
                  )}
                  {errors.mob_no &&
                    errors.mob_no.type === "startsWithValidDigit" && (
                      <span className="fs-8 text-danger">
                        {errors.mob_no.message}
                      </span>
                    )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Email Address<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email address"
                    id="email-forms-email"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="fs-8 text-danger">
                      Email Address is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    User<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={user.map((api) => {
                          return {
                            label: api.first_name + " " + api.last_name,
                            value: api.id,
                          };
                        })}
                        isClearable={true}
                        id="select-forms-user"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user"
                    control={control}
                  />
                  {errors.user && (
                    <span className="fs-8 text-danger">User is required</span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-client-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-client"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditClient;
