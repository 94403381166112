import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useContext } from "react";
import { LogoutContext } from "../Context/LogoutContext";

const UseAPI = () => {
  const env_url = process.env.REACT_APP_API_URL
  console.log(env_url)

  const { logoutHandler } = useContext(LogoutContext);

  // Create an Axios instance
  const api = axios.create({
    baseURL: env_url,
  });

  // Request interceptor to add token to the headers
  api.interceptors.request.use(
    async (config) => {
      const token = secureLocalStorage.getItem("accessToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor to handle token expiration
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // const { logoutHandler } = useContext(UsersContext);

      const originalRequest = error.config;

      // If access token is expired, handle it by refreshing
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark the request as retried
        const refresh = secureLocalStorage.getItem("refreshToken");

        if (refresh) {
          try {
            // Attempt to refresh the access token
            const response = await axios.post(
              `${env_url}/auth/token/refresh/`,
              {
                refresh: refresh,
              }
            );
            const { access } = response.data;

            // Store the new access token
            secureLocalStorage.setItem("accessToken", access);

            // Update the Authorization header and retry the original request
            originalRequest.headers["Authorization"] = `Bearer ${access}`;
            return api(originalRequest); // Retry the original request
          } catch (refreshError) {
            console.error("Token refresh failed:", refreshError);
            // Logout the user

            logoutHandler();
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export default UseAPI;
