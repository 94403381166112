import React, { useState, createContext } from "react";
import UseAPI from "../Token/token_refresh";

export const WorkOrderContext = createContext(null);

function WorkOrderContextProvider({ children }) {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  console.log(env_url);

  const [isWorkOrderLoading, setIsWorkOrderLoading] = useState(false);
  const [rawWorkOrderDetails, setRawWorkOrderDetails] = useState([]);
  const [workOrderTotalCount, setWorkOrderTotalCount] = useState(null);

  const getWorkOrderData = async (page, entries, search, filter, sort) => {
    setIsWorkOrderLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append("event", search);
    if (sort?.key) queryParams.append("sortkey", sort.key);
    if (sort?.direction) queryParams.append("sortOrder", sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(","));
        }
      });
    }

    let url = env_url + `/api/work-order/?entries=${entries}&page_no=${page}`;

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data);
      setIsWorkOrderLoading(false);
      if (response.length !== 0) {
        setRawWorkOrderDetails(res.data.results?.data);
      } else {
        setRawWorkOrderDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setWorkOrderTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsWorkOrderLoading(false);
    }
  };

  return (
    <div>
      <WorkOrderContext.Provider
        value={{
          isWorkOrderLoading,
          rawWorkOrderDetails,
          workOrderTotalCount,
          getWorkOrderData,
        }}
      >
        {children}
      </WorkOrderContext.Provider>
    </div>
  );
}

export default WorkOrderContextProvider;
