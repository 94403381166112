import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LogoutContextProvider from "./Component/Context/LogoutContext";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SettingContextProvider from "./Component/Context/SettingContext";
import SalesOrderContextProvider from "./Component/Context/SalesOrderContext";
import ClientContextProvider, { ClientContext } from "./Component/Context/ClientContext";
import ProductContextProvider from "./Component/Context/ProductContext";
import GenericContextProvider from "./Component/Context/GenericContext";
import TypeMasterContextProvider from "./Component/Context/TypeMasterContext";
import BOMContextProvider from "./Component/Context/BOMContext";
import WorkOrderContextProvider from "./Component/Context/WorkOrderContext";
import BOContextProvider from "./Component/Context/BOContext";
import StockContextProvider from "./Component/Context/StockContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="441845017853-ujlaif8glhghr62v4lfq50mbldcjsqin.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter>
        <LogoutContextProvider>
          <SettingContextProvider>
            <SalesOrderContextProvider>
              <ClientContextProvider>
                <ProductContextProvider>
                  <GenericContextProvider>
                    <TypeMasterContextProvider>
                      <BOMContextProvider>
                        <WorkOrderContextProvider>
                          <BOContextProvider>
                            <StockContextProvider>
                              <App />
                            </StockContextProvider>
                          </BOContextProvider>
                        </WorkOrderContextProvider>
                      </BOMContextProvider>
                    </TypeMasterContextProvider>
                  </GenericContextProvider>
                </ProductContextProvider>
              </ClientContextProvider>
            </SalesOrderContextProvider>
          </SettingContextProvider>
        </LogoutContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
