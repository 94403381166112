import axios from "axios";
import React, { useState, createContext } from "react";
import UseAPI from "../Token/token_refresh";

export const ClientContext = createContext(null);

function ClientContextProvider({ children }) {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  console.log(env_url);

  const [isClientLoading, setIsClientLoading] = useState(false);
  const [rawClientDetails, setRawClientDetails] = useState([]);
  const [clientTotalCount, setClientTotalCount] = useState(null);

  const getClientData = async (page, entries, search, filter, sort) => {
    setIsClientLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append("event", search);
    if (sort?.key) queryParams.append("sortkey", sort.key);
    if (sort?.direction) queryParams.append("sortOrder", sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(","));
        }
      });
    }

    let url = env_url + `/api/client/?entries=${entries}&page_no=${page}`;

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data);
      setIsClientLoading(false);
      if (response.length !== 0) {
        setRawClientDetails(res.data.results?.data);
      } else {
        setRawClientDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setClientTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsClientLoading(false);
    }
  };

  return (
    <div>
      <ClientContext.Provider
        value={{
          isClientLoading,
          rawClientDetails,
          clientTotalCount,
          getClientData,
        }}
      >
        {children}
      </ClientContext.Provider>
    </div>
  );
}

export default ClientContextProvider;
