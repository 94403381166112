import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UseAPI from "../../Token/token_refresh"
import Swal from "sweetalert2";
import { ClientContext } from "../../Context/ClientContext";

const columns = [
  {
    accessor: "client_name",
    header: "Client Name",
  },
  {
    accessor: "client_location",
    header: "Client Location",
  },
  {
    accessor: "gst_no",
    header: "GST No",
  },
  {
    accessor: "gst_cert",
    header: "GST Certificate",
    cell: (row) => <a target="_blank" href={row?.gst_cert}>GST Certificate</a>
  },
  {
    accessor: "mob_no",
    header: "Mobile Number",
  },
  {
    accessor: "email",
    header: "Email Address",
  },
  {
    accessor: "user",
    header: "User",
    cell: (row) => <>{row?.user?.first_name + " " + row?.user?.last_name}</>
  },
];

const Client = () => {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const [clientEntries, setClientEntries] = useState(5);
  const [clientPageIndex, setClientPageIndex] = useState(0);

  const {
    isClientLoading,
    rawClientDetails,
    clientTotalCount,
    getClientData,
  } = useContext(ClientContext);

  const handleGlobalFilterChange = (value) => {
    console.log(value);
    getClientData(0, clientEntries, value);
  };

  const handlePagination = (value) => {
    console.log(value);
    getClientData(value.pageIndex, value.pageSize);
    setClientPageIndex(value.pageIndex);
    setClientEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-client", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteClientEntry(entry_id);
      }
    });
  };

  const deleteClientEntry = (id) => {
    const url = env_url + `/api/client/${id}/`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
    };

    api(config).then((response) => {
      console.log(response.data);
      Swal.fire({
        title: "Entry Deleted!",
        text: "The selected entry have been successfully deleted.",
        icon: "success"
      });
      getClientData(0, 5)
    }).catch((error) => {
      console.log(error);
      Swal.fire({
        title: "Entry not Deleted!",
        text: "The selected entry has not been successfully deleted.",
        icon: "error"
      });
    });
  };

  useEffect(() => {
    getClientData(clientPageIndex,clientEntries);
  },[]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Client</h3>
            {isClientLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content">
            <NavLink
              to="/create-client"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-client"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-client"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-client"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create Client</span>
            </NavLink>
          </div>
        </div>
      </div>

      <Table
        data={rawClientDetails}
        columns={columns}
        pagination
        pageIndex={clientPageIndex}
        pageSize={clientEntries}
        rowCount={clientTotalCount}
        globalSearch
        editRows
        deleteRows
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default Client;
