import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Role from '../Pages/Setting/Roles/Role'
import CreateRole from '../Pages/Setting/Roles/CreateRole'
import EditRole from '../Pages/Setting/Roles/EditRole'
import Privilege from '../Pages/Setting/Privilege/Privilege'
import CreatePrivilege from '../Pages/Setting/Privilege/CreatePrivilege'
import EditPrivilege from '../Pages/Setting/Privilege/EditPrivilege'
import TypeMaster from '../Pages/Setting/TypeMaster/TypeMaster'
import CreateTypeMaster from '../Pages/Setting/TypeMaster/CreateTypeMaster'
import EditTypeMaster from '../Pages/Setting/TypeMaster/EditTypeMaster'
import TypeMasterCategory from '../Pages/Setting/TypeMasterCategory/TypeMasterCategory'
import CreateTypeMasterCategory from '../Pages/Setting/TypeMasterCategory/CreateTypeMasterCategory'
import EditTypeMasterCategory from '../Pages/Setting/TypeMasterCategory/EditTypeMasterCategory'
import SalesOrder from '../Pages/SalesOrder/SalesOrder'
import CreateSalesOrder from '../Pages/SalesOrder/CreateSalesOrder'
import EditSalesOrder from '../Pages/SalesOrder/EditSalesOrder'
import Client from '../Pages/Client/Client'
import CreateClient from '../Pages/Client/CreateClient'
import EditClient from '../Pages/Client/EditClient'
import BOM from '../Pages/BOM/BOM'
import CreateBOM from '../Pages/BOM/CreateBOM'
import EditBOM from '../Pages/BOM/EditBOM'
import Product from '../Pages/Product/Product'
import CreateProduct from '../Pages/Product/CreateProduct'
import EditProduct from '../Pages/Product/EditProduct'
import DefaultWorkOrder from '../Pages/WorkOrder/DefaultWorkOrder'
import WorkOrder from '../Pages/WorkOrder/WorkOrder'
import CreateWorkOrder from '../Pages/WorkOrder/CreateWorkOrder'
import EditWorkOrder from '../Pages/WorkOrder/EditWorkOrder'
import BO from '../Pages/BO/BO'
import EditBO from '../Pages/BO/EditBO'
import CreateBO from '../Pages/BO/CreateBO'
import StockTranscation from "../Pages/StockTranscation/StockTranscation"
import CreateStockTranscation from "../Pages/StockTranscation/CreateStockTranscation"
import EditStockTranscation from "../Pages/StockTranscation/EditStockTranscation"
import secureLocalStorage from 'react-secure-storage'
import Error from '../ErrorPages/Error'
import NotFound from '../ErrorPages/NotFound'

const Content = () => {
  const privileges = secureLocalStorage.getItem("privileges")
  return (
    <>
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <Routes>
            {privileges.includes("sales_order_retrieve") ? (<Route path='/' element={<SalesOrder />} />) : (<Route path="/" element={<NotFound />} />) }
            {privileges.includes("sales_order_create") ? (<Route path='/create-sales-order' element={<CreateSalesOrder />} />) : (<Route path="/create-sales-order" element={<NotFound />} />) }
            {privileges.includes("sales_order_update") ? (<Route path='/edit-sales-order' element={<EditSalesOrder />} />) : (<Route path="/edit-sales-order" element={<NotFound />} />)}

            {privileges.includes("client_retrieve") ? (<Route path='/client' element={<Client />} />) : (<Route path="/client" element={<NotFound />} />)}
            {privileges.includes("client_create") ? (<Route path='/create-client' element={<CreateClient />} />) : (<Route path="/create-client" element={<NotFound />} />)}
            {privileges.includes("client_update") ? (<Route path='/edit-client' element={<EditClient />} />) : (<Route path="/edit-client" element={<NotFound />} />)}

            {privileges.includes("bom_retrieve") ? (<Route path='/bom' element={<BOM />} />) : (<Route path="/bom" element={<NotFound />} />) }
            {privileges.includes("bom_create") ? (<Route path='/create-bom' element={<CreateBOM />} />) : (<Route path="/create-bom" element={<NotFound />} />)}
            {privileges.includes("bom_update") ? (<Route path='/edit-bom' element={<EditBOM />} />) : (<Route path="/edit-bom" element={<NotFound />} />)}

            {privileges.includes("item_retrieve") ? (<Route path='/item' element={<Product />} />) : (<Route path="/item" element={<NotFound />} />)}
            {privileges.includes("item_create") ? (<Route path='/create-item' element={<CreateProduct />} />) : (<Route path="/create-item" element={<NotFound />} />)}
            {privileges.includes("item_update") ? (<Route path='/edit-item' element={<EditProduct />} />) : (<Route path="/edit-item" element={<NotFound />} />)}

            {privileges.includes("default_work_order") ? (<Route path='/sales-work-order' element={<DefaultWorkOrder />} />) : (<Route path="/sales-work-order" element={<NotFound />} />)}

            {privileges.includes("work_order_retrieve") ? (<Route path='/work-order' element={<WorkOrder />} />) : (<Route path="/work-order" element={<NotFound />} />)}
            {privileges.includes("work_order_create") ? (<Route path='/create-work-order' element={<CreateWorkOrder />} />) : (<Route path="/create-work-order" element={<NotFound />} />)}
            {privileges.includes("work_order_update") ? (<Route path='/edit-work-order' element={<EditWorkOrder />} />) : (<Route path="/edit-work-order" element={<NotFound />} />)}

            {privileges.includes("bo_retrieve") ? (<Route path='/bo' element={<BO />} />) : (<Route path="/bo" element={<NotFound />} />)}
            {privileges.includes("bo_create") ? (<Route path='/create-bo' element={<CreateBO />} />) : (<Route path="/create-bo" element={<NotFound />} />)}
            {privileges.includes("bo_update") ? (<Route path='/edit-bo' element={<EditBO />} />) : (<Route path="/edit-bo" element={<NotFound />} />)}

            {privileges.includes("stock_transcation_retrieve") ? (<Route path='/stock-transcation' element={<StockTranscation />} />): (<Route path="/stock-transcation" element={<NotFound />} />)}
            {privileges.includes("stock_transcation_create") ? (<Route path='/create-stock-transcation' element={<CreateStockTranscation />} />) : (<Route path="/create-stock-transcation" element={<NotFound />} />)}
            {privileges.includes("stock_transcation_update") ? (<Route path='/edit-stock-transcation' element={<EditStockTranscation />} />) : (<Route path="/edit-stock-transcation" element={<NotFound />} />)}

            {privileges.includes("role_retrieve") ? (<Route path='/role' element={<Role />} />) : (<Route path="/role" element={<NotFound />} />)}
            {privileges.includes("role_create") ? (<Route path='/create-role' element={<CreateRole />} />) : (<Route path="/create-role" element={<NotFound />} />)}
            {privileges.includes("role_update") ? (<Route path='/edit-role' element={<EditRole />} />) : (<Route path="/edit-role" element={<NotFound />} />)}

            {privileges.includes("privilege_retrieve") ? (<Route path='/privilege' element={<Privilege />} />) : (<Route path="/privilege" element={<NotFound />} />)}
            {privileges.includes("privilege_create") ? (<Route path='/create-privilege' element={<CreatePrivilege />} />) : (<Route path="/create-privilege" element={<NotFound />} />)}
            {privileges.includes("privilege_update") ? (<Route path='/edit-privilege' element={<EditPrivilege />} />) : (<Route path="/edit-privilege" element={<NotFound />} />)}

            {privileges.includes("type_master_retrieve") ? (<Route path='/type-master' element={<TypeMaster />} />) : (<Route path="/type-master" element={<NotFound />} />)}
            {privileges.includes("type_master_create") ? (<Route path='/create-type-master' element={<CreateTypeMaster />} />) :(<Route path="/create-type-master" element={<NotFound />} />)}
            {privileges.includes("type_master_update") ? (<Route path='/edit-type-master' element={<EditTypeMaster />} />) : (<Route path="/edit-type-master" element={<NotFound />} />)}

            {privileges.includes("type_master_category_retrieve") ? (<Route path='/type-master-category' element={<TypeMasterCategory />} />) : (<Route path="/type-master-category" element={<NotFound />} />)}
            {privileges.includes("type_master_category_create") ? (<Route path='/create-type-master-category' element={<CreateTypeMasterCategory />} />) : (<Route path="/create-type-master-category" element={<NotFound />} />)}
            {privileges.includes("type_master_category_update") ? (<Route path='/edit-type-master-category' element={<EditTypeMasterCategory />} />) : (<Route path="/edit-type-master-category" element={<NotFound />} />)}


            <Route path="*" element={<Error />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content