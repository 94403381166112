import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import Select from "react-select";
import { ProductContext } from "../../Context/ProductContext"
import { TypeMasterContext } from "../../Context/TypeMasterContext";
import secureLocalStorage from "react-secure-storage";

const CreateProduct = () => {
  const userID = secureLocalStorage.getItem("userID")
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { getProductData } = useContext(ProductContext);
  const { procurementTypeDropdown, materialTypeDropdown, materialSubTypeDropdown, categoryDropdown } = useContext(TypeMasterContext)

  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var product = JSON.stringify({
      item_name: data.item_name,
      threshold_amount: data.threshold_amount,
      item_code: data.item_code,
      lead_time: data.lead_time,
      procurement_type: data.procurement_type.value,
      material_type: data.material_type.value,
      material_subtype: data.material_subtype.value,
      category: data.category.value,
      stock_quantity: data.stock_quantity,
      // created_by: userID,
      // updated_by: userID,
    });

    const url = env_url + "/api/item/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: product,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Item Added!",
          text: "The item details have been successfully added.",
          icon: "success",
        });
        setSubmitLoading(false);
        getProductData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Adding Item",
          text: "There was an issue adding the item details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Item </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter item name"
                    id="text-forms-item_name"
                    {...register("item_name", { required: true })}
                  />
                  {errors.item_name && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Threshold Amount<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter threshold amount"
                    id="email-forms-threshold_amount"
                    {...register("threshold_amount", { required: true })}
                  />
                  {errors.threshold_amount && (
                    <span className="fs-8 text-danger">
                      Threshold amount is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Lead Time<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter lead time"
                    id="email-forms-lead_time"
                    {...register("lead_time", { required: true })}
                  />
                  {errors.lead_time && (
                    <span className="fs-8 text-danger">
                      Lead time is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Procurement Type<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={procurementTypeDropdown?.map((api) => {
                          return {value:api.name, label: api.name}
                        })}
                        isClearable={true}
                        id="select-forms-procurement_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="procurement_type"
                    control={control}
                  />
                  {errors.procurement_type && (
                    <span className="fs-8 text-danger">
                      Procurement Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Material Type<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={materialTypeDropdown?.map((api) => {
                          return {label:api.name, value:api.name}
                        })}
                        isClearable={true}
                        id="select-forms-material_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="material_type"
                    control={control}
                  />
                  {errors.material_type && (
                    <span className="fs-8 text-danger">
                      Material Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Material Sub Type<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={materialSubTypeDropdown?.map((api) => {
                          return {label:api.name, value:api.name}
                        })}
                        isClearable={true}
                        id="select-forms-material_subtype"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="material_subtype"
                    control={control}
                  />
                  {errors.material_subtype && (
                    <span className="fs-8 text-danger">
                      Material Sub Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                  Category Type<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={categoryDropdown?.map((api) => {
                          return {label : api.name, value:api.name}
                        })}
                        isClearable={true}
                        id="select-forms-category"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Stock Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter stock quantity"
                    id="email-forms-stock_quantity"
                    {...register("stock_quantity", { required: true })}
                  />
                  {errors.stock_quantity && (
                    <span className="fs-8 text-danger">
                      Stock Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-product-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-product"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProduct;
