import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import Select from "react-select";
import { SalesOrderContext } from "../../Context/SalesOrderContext";
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../Context/GenericContext";

const EditSalesOrder = () => {
  const userID = secureLocalStorage.getItem("userID");
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [enableStock, setEnableStock] = useState(false);
  const { getSalesOrderData } = useContext(SalesOrderContext);
  const { productDropdown, citiesDropdown, clientDropdown, getGenericData } =
    useContext(GenericContext);

  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var sale_order = JSON.stringify({
      product_id: data.product.value,
      client_id: data.client.value,
      target_date: data.target_date,
      dispatch_location: data.dispatch_location.value,
      quantity: data.quantity,
      to_be_produced: location?.state?.editpost?.to_be_produced - data.stock_allotted,
      stock_allotted: data.stock_allotted,
      // updated_by: userID,
    });

    const url = env_url + `/api/sales-order/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: sale_order,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Sales Order Updated!",
          text: "The sales order details have been successfully updated.",
          icon: "success",
        });
        setSubmitLoading(false);
        getSalesOrderData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Updating Sales Order",
          text: "There was an issue updating the sale order details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editSalesOrder = location.state.editpost;
    // defaultValues is a react form hook.
    if (editSalesOrder) {
      var defaultValues = {};
      defaultValues.target_date = editSalesOrder.target_date;
      defaultValues.quantity = editSalesOrder.quantity;

      const getProduct = {
        value: editSalesOrder.product?.id,
        label: editSalesOrder.product?.item_name,
        item_code: editSalesOrder.product?.item_code,
        stock_quantity: editSalesOrder.product?.stock_quantity,
        bom_code: editSalesOrder.product?.bom_code,
      };
      defaultValues.product = getProduct;
      setSelectedProduct(getProduct)

      const getClient = {
        value: editSalesOrder.client?.id,
        label: editSalesOrder.client?.client_name,
      };
      defaultValues.client = getClient;

      const getDispatchLocation = {
        value: editSalesOrder.dispatch_location,
        label: editSalesOrder.dispatch_location,
      };
      defaultValues.dispatch_location = getDispatchLocation;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      setValue("stock_allotted", selectedProduct.stock_quantity);
    } else {
      setValue("stock_allotted", null);
    }
  }, [selectedProduct, setValue]);

  useEffect(() => {
    getGenericData();
  },[])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Sales Order </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Product Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={productDropdown.map((api) => {
                          return {
                            label: api.item_name,
                            value: api.id,
                            item_code: api.item_code,
                            stock_quantity: api.stock_quantity,
                            bom_code: api.bom_code,
                          };
                        })}
                        // value={selectedProduct}
                        isClearable={true}
                        id="select-forms-product"
                        onChange={(e) => {
                          setSelectedProduct(e);
                          field.onChange(e);
                        }}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="product"
                    control={control}
                  />
                  {errors.product && (
                    <span className="fs-8 text-danger">
                      Product Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label"> Product ID </label>
                  <div className="text-dark fw-bold fs-6">
                    {selectedProduct
                      ? selectedProduct.item_code
                      : "Please select Product first"}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="d-flex justify-content-between">
                    <label className="form-label"> FG Stock </label>
                    <button
                      type="button"
                      className="btn btn-xs btn-primary"
                      onClick={() => setEnableStock(!enableStock)}
                    >
                      {enableStock ? (
                        <em className="icon ni ni-cross"></em>
                      ) : (
                        <em className="icon ni ni-edit-alt"></em>
                      )}
                    </button>
                  </div>
                  <div className="text-dark fw-bold fs-6">
                    {enableStock ? (
                      <input
                        type="number"
                        className="form-control"
                        id="email-forms-stock_allotted"
                        {...register("stock_allotted", {
                          required: "Stock Allotted is required",
                          max: {
                            value: selectedProduct?.stock_quantity,
                            message: `Budget cannot exceed the allotted stock of ${selectedProduct?.stock_allotted}`,
                          },
                        })}
                      />
                    ) : selectedProduct ? (
                      selectedProduct.stock_quantity
                    ) : (
                      "Please select Product first"
                    )}
                  </div>
                  {errors.stock_allotted && (
                    <span className="fs-8 text-danger">
                      {errors.stock_allotted.message}
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label"> BOM ID </label>
                  <div className="text-dark fw-bold fs-6">
                    {selectedProduct
                      ? selectedProduct.bom_code
                      : "Please select Product first"}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Client Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={clientDropdown.map((api) => {
                          return { label: api.client_name, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-client"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="client"
                    control={control}
                  />
                  {errors.client && (
                    <span className="fs-8 text-danger">
                      Client Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter no of quantity"
                    id="email-forms-quantity"
                    {...register("quantity", { required: true })}
                  />
                  {errors.quantity && (
                    <span className="fs-8 text-danger">
                      Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Target Date<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="email-forms-target_date"
                    {...register("target_date", { required: true })}
                  />
                  {errors.target_date && (
                    <span className="fs-8 text-danger">
                      Target Date is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Dispatch Location
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={citiesDropdown.map((api) => {
                          return { label: api, value: api };
                        })}
                        isClearable={true}
                        id="select-forms-dispatch_location"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="dispatch_location"
                    control={control}
                  />
                  {errors.dispatch_location && (
                    <span className="fs-8 text-danger">
                      Dispatch Location is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-sales-order-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-sales-order"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSalesOrder;
