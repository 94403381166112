import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../Context/GenericContext";
import { StockContext } from "../../Context/StockContext";
import { TypeMasterContext } from "../../Context/TypeMasterContext"

const EditStockTranscation = () => {
  const userID = secureLocalStorage.getItem("userID");
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const { getStockTranscationData } = useContext(StockContext);
  const {
    jobCardDropdown,
    purchaseOrderDropdown,
    requisitionDropdown,
    salesOrderDropdown,
    itemDropdown,
  } = useContext(GenericContext);
  const { updateTypeDropdown, transcationTypeDropdown } =
    useContext(TypeMasterContext);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var stock = JSON.stringify({
      quantity: data.quantity,
      purchase_order_id: data.purchase_order_id.value,
      job_card_id: data.job_card_id.value,
      update_type: data.update_type.value,
      requisition_id: data.requisition_id.value,
      transaction_type: data.transaction_type.value,
      store_approval: data.store_approval.value,
      QC_approval: data.QC_approval.value,
      department_manager_approval: data.department_manager_approval.value,
      so_id: data.so_id.value,
      item_id: data.item_id.value,
      is_locked: data.is_locked.value,
      // updated_by: userID,
    });

    const url = env_url + `/api/stock-transcation/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: stock,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Stock Transcation Updated!",
          text: "The stock transcation details have been successfully updated.",
          icon: "success",
        });
        setSubmitLoading(false);
        getStockTranscationData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Updating stock transcation",
          text: "There was an issue updating the stock transcation details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editStock = location.state.editpost;
    // defaultValues is a react form hook.
    if (editStock) {
      var defaultValues = {};
      defaultValues.quantity = editStock.quantity;

      const getTranscationOrder = {
        label: editStock.purchase_order?.purchase_order_name,
        value: editStock.purchase_order?.id,
      };
      defaultValues.purchase_order_id = getTranscationOrder;

      const getJobCard = {
        label: editStock.job_card?.jobcard_name,
        value: editStock.job_card?.id,
      };
      defaultValues.job_card_id = getJobCard;

      const getUpdateType = {
        label: editStock.update_type,
        value: editStock.update_type,
      };
      defaultValues.update_type = getUpdateType;

      const getRequisition = {
        label: editStock.requisition?.requisition_name,
        value: editStock.requisition?.id,
      };
      defaultValues.requisition_id = getRequisition;

      const getTranscationType = {
        label: editStock.transaction_type,
        value: editStock.transaction_type,
      };
      defaultValues.transaction_type = getTranscationType;

      const getStockApproval = {
        label: editStock.store_approval,
        value: editStock.store_approval,
      };
      defaultValues.store_approval = getStockApproval;

      const getQAApproval = {
        label: editStock.QC_approval,
        value: editStock.QC_approval,
      };
      defaultValues.QC_approval = getQAApproval;

      const getDepartmentApproval = {
        label: editStock.department_manager_approval,
        value: editStock.department_manager_approval,
      };
      defaultValues.department_manager_approval = getDepartmentApproval;

      const getIsLocked = {
        label: editStock.is_locked,
        value: editStock.is_locked,
      };
      defaultValues.is_locked = getIsLocked;

      const getSalesOrder = {
        label: editStock.sales_order?.so_number,
        value: editStock.sales_order?.id,
      };
      defaultValues.so_id = getSalesOrder;

      const getItemName = {
        label: editStock.item?.item_name,
        value: editStock.item?.id,
      };
      defaultValues.item_id = getItemName;

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Stock Transcation </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter quantity"
                    id="email-forms-quantity"
                    {...register("quantity", { required: true })}
                  />
                  {errors.quantity && (
                    <span className="fs-8 text-danger">
                      Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Purchase Order<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={purchaseOrderDropdown.map((api) => {
                          return {
                            value: api.id,
                            label: api.purchase_order_name,
                          };
                        })}
                        isClearable={true}
                        id="select-forms-purchase_order_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="purchase_order_id"
                    control={control}
                  />
                  {errors.purchase_order_id && (
                    <span className="fs-8 text-danger">
                      Purchase Order is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Job Card<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={jobCardDropdown.map((api) => {
                          return { label: api.jobcard_name, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-job_card_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="job_card_id"
                    control={control}
                  />
                  {errors.job_card_id && (
                    <span className="fs-8 text-danger">
                      Job card is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Update Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={updateTypeDropdown.map((api) => {
                          return { label: api.name, value: api.name };
                        })}
                        isClearable={true}
                        id="select-forms-update_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="update_type"
                    control={control}
                  />
                  {errors.update_type && (
                    <span className="fs-8 text-danger">
                      Update Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Requisition Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={requisitionDropdown.map((api) => {
                          return { label: api.requisition_name, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-requisition_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="requisition_id"
                    control={control}
                  />
                  {errors.requisition_id && (
                    <span className="fs-8 text-danger">
                      Requisition Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Transcation Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={transcationTypeDropdown.map((api) => {
                          return { label: api.name, value: api.name };
                        })}
                        isClearable={true}
                        id="select-forms-transaction_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="transaction_type"
                    control={control}
                  />
                  {errors.transaction_type && (
                    <span className="fs-8 text-danger">
                      Transcation Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Store Approval
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          {label: "True", value: "True"},
                          {label: "False", value: "False"}
                        ]}
                        isClearable={true}
                        id="select-forms-store_approval"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="store_approval"
                    control={control}
                  />
                  {errors.store_approval && (
                    <span className="fs-8 text-danger">
                      Store Approval is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    QC Approval
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          {label: "True", value: "True"},
                          {label: "False", value: "False"}
                        ]}
                        isClearable={true}
                        id="select-forms-QC_approval"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="QC_approval"
                    control={control}
                  />
                  {errors.QC_approval && (
                    <span className="fs-8 text-danger">
                      QC Approval is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                      Department manager approval
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          {label: "True", value: "True"},
                          {label: "False", value: "False"}
                        ]}
                        isClearable={true}
                        id="select-forms-department_manager_approval"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="department_manager_approval"
                    control={control}
                  />
                  {errors.department_manager_approval && (
                    <span className="fs-8 text-danger">
                      Department manager approval is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sales Order Number
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={salesOrderDropdown.map((api) => {
                          return { label: api.so_number, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-so_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="so_id"
                    control={control}
                  />
                  {errors.so_id && (
                    <span className="fs-8 text-danger">
                      Sales Order number is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={itemDropdown.map((api) => {
                          return { label: api.item_name, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-item_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                      Is Locked
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          {label: "True", value: "True"},
                          {label: "False", value: "False"}
                        ]}
                        isClearable={true}
                        id="select-forms-is_locked"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="is_locked"
                    control={control}
                  />
                  {errors.is_locked && (
                    <span className="fs-8 text-danger">
                      Is Locked is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-stock-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-stock"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStockTranscation;
