import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import Login from "./Component/Authentication/Login";
import Base from "./Component/Base/Base";
import { LogoutContext } from "./Component/Context/LogoutContext";

const App = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(LogoutContext);
  // Set values in localstorage,
  const loginHandler = (data) => {
    console.log(data);
    secureLocalStorage.setItem("isLoggedIn", "USER_LOGGED_IN");
    secureLocalStorage.setItem("userID", data.id);
    secureLocalStorage.setItem("username", data.username);
    secureLocalStorage.setItem("firstName", data.first_name);
    secureLocalStorage.setItem("lastName", data.last_name);
    secureLocalStorage.setItem("email", data.email);
    secureLocalStorage.setItem("privileges", data.privileges);
    secureLocalStorage.setItem("accessToken", data.access_token);
    setIsLoggedIn(true);
  };

  secureLocalStorage.setItem("privileges", [
    "sales_order_retrieve",
    "sales_order_create",
    "sales_order_update",
    "sales_order_delete",
    "default_work_order",
    "master",
    "client_retrieve",
    "client_create",
    "client_update",
    "client_delete",
    "bom_retrieve",
    "bom_create",
    "bom_update",
    "bom_delete",
    "item_retrieve",
    "item_create",
    "item_update",
    "item_delete",
    "work_order_retrieve",
    "work_order_create",
    "work_order_update",
    "work_order_delete",
    "stock_transcation_retrieve",
    "stock_transcation_create",
    "stock_transcation_update",
    "stock_transcation_delete",
    "bo_retrieve",
    "bo_create",
    "bo_update",
    "bo_delete",
    "privilege_retrieve",
    "privilege_create",
    "privilege_update",
    "privilege_delete",
    "role_retrieve",
    "role_create",
    "role_update",
    "role_delete",
    "type_master_retrieve",
    "type_master_create",
    "type_master_update",
    "type_master_delete",
    "type_master_category_retrieve",
    "type_master_category_create",
    "type_master_category_update",
    "type_master_category_delete"
  ]
  );

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem("isLoggedIn");
    if (userStatus === "USER_LOGGED_IN") {
      setIsLoggedIn(true);
    } else if (userStatus === "USER_LOGGED_OUT") {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      {isLoggedIn && <Base />}
      {!isLoggedIn && <Login loginHandler={loginHandler} />}
    </>
  );
};

export default App;
