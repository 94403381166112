import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const handleActive = (status) => {
    setSidebarActive(status);
  };

  const [sidebarMasterActive, setSidebarMasterActive] = useState(false);
  const handleMasterActive = (status) => {
    setSidebarMasterActive(status);
  };

  return (
    <>
      <div
        className="nk-sidebar is-light nk-sidebar-fixed is-light "
        data-content="sidebarMenu"
      >
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <a href="javascript:void(0)" className="logo-link nk-sidebar-logo">
              <img
                className="logo-light logo-img"
                src="./assets/images/gev-logo-dark.png"
                srcSet="./assets/images/gev-logo-dark.png 2x"
                alt="gev-logo"
              />
              <img
                className="logo-dark logo-img"
                src="./assets/images/gev-logo-dark.png"
                srcSet="./assets/images/gev-logo-dark.png 2x"
                alt="gev-logo"
              />
              <img
                className="logo-small logo-img logo-img-small"
                src="./assets/images/gev-logo-dark.png"
                srcSet="./assets/images/gev-logo-dark.png 2x"
                alt="gev-logo"
              />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                <li className="nk-menu-item">
                  <NavLink to="/" className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-tag"></em>
                    </span>
                    <span className="nk-menu-text">Sales Order</span>
                  </NavLink>
                </li>
                <li
                  className={`${
                    sidebarMasterActive
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                  }`}
                >
                  <a
                    href="javascript:void(0)"
                    className="nk-menu-link nk-menu-toggle"
                    onClick={() => handleMasterActive(!sidebarMasterActive)}
                    id="sidebar-setting"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-external"></em>
                    </span>
                    <span className="nk-menu-text">Master</span>
                  </a>
                  <ul className="nk-menu-sub">
                    <li className="nk-menu-item">
                      <NavLink to="/client" className="nk-menu-link">
                        {/* <span className="nk-menu-icon">
                          <em className="icon ni ni-users"></em>
                        </span> */}
                        <span className="nk-menu-text">Client</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/bom" className="nk-menu-link">
                        {/* <span className="nk-menu-icon">
                          <em className="icon ni ni-file-text"></em>
                        </span> */}
                        <span className="nk-menu-text">BOM</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/item" className="nk-menu-link">
                        {/* <span className="nk-menu-icon">
                          <em className="icon ni ni-card-view"></em>
                        </span> */}
                        <span className="nk-menu-text">Item</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/work-order" className="nk-menu-link">
                        {/* <span className="nk-menu-icon">
                          <em className="icon ni ni-cc"></em>
                        </span> */}
                        <span className="nk-menu-text">Work Order</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/stock-transcation" className="nk-menu-link">
                        {/* <span className="nk-menu-icon">
                          <em className="icon ni ni-tranx"></em>
                        </span> */}
                        <span className="nk-menu-text">Stock Transaction</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink to="/bo" className="nk-menu-link">
                        {/* <span className="nk-menu-icon">
                          <em class="icon ni ni-newspaper"></em>
                        </span> */}
                        <span className="nk-menu-text">BO</span>
                      </NavLink>
                    </li>
                  </ul>
                  {/* <!-- .nk-menu-sub --> */}
                </li>
                <li
                  className={`${
                    sidebarActive
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                  }`}
                >
                  <a
                    href="javascript:void(0)"
                    className="nk-menu-link nk-menu-toggle"
                    onClick={() => handleActive(!sidebarActive)}
                    id="sidebar-setting"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-setting"></em>
                    </span>
                    <span className="nk-menu-text">Settings</span>
                  </a>
                  <ul className="nk-menu-sub">
                    <li className="nk-menu-item">
                      <NavLink
                        to="/privilege"
                        className="nk-menu-link"
                        id="sidebar-privilege"
                      >
                        <span className="nk-menu-text">Privileges</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/role"
                        className="nk-menu-link"
                        id="sidebar-role"
                      >
                        <span className="nk-menu-text">Role</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/type-master"
                        className="nk-menu-link"
                        id="sidebar-type-master"
                      >
                        <span className="nk-menu-text">Type Master</span>
                      </NavLink>
                    </li>
                    <li className="nk-menu-item">
                      <NavLink
                        to="/type-master-category"
                        className="nk-menu-link"
                        id="sidebar-type-master-category"
                      >
                        <span className="nk-menu-text">
                          Type Master Category
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                  {/* <!-- .nk-menu-sub --> */}
                </li>
                {/* <!-- .nk-menu-item --> */}
              </ul>
              {/* <!-- .nk-menu --> */}
            </div>
            {/* <!-- .nk-sidebar-menu --> */}
          </div>
          {/* <!-- .nk-sidebar-content --> */}
        </div>
        {/* <!-- .nk-sidebar-element --> */}
      </div>
    </>
  );
};

export default Sidebar;
