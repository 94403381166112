import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../Context/GenericContext";
import { BOContext } from "../../Context/BOContext";

const CreateBO = () => {
  const userID = secureLocalStorage.getItem("userID");
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { getBOData } = useContext(BOContext);
  const { supplierDropdown, itemDropdown, bomDropdown, workOrderDropdown } = useContext(GenericContext);

  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var bo = JSON.stringify({
      item_id: data.item_id.value,
      work_order_req_quantity: data.work_order_req_quantity,
      bom_id: data.bom_id.value,
      work_order_id: data.work_order_id.value,
      supplier_id: data.supplier_id.value,
      // created_by: userID,
      // updated_by: userID,
    });

    const url = env_url + "/api/bo/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: bo,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "BO Added!",
          text: "The bo details have been successfully added.",
          icon: "success",
        });
        setSubmitLoading(false);
        getBOData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Adding BO",
          text: "There was an issue adding the bo details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create BO </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={itemDropdown?.map((api) => {
                          return { label: api.item_name, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-item_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work Order Request Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter work order request quantity"
                    id="email-forms-work_order_req_quantity"
                    {...register("work_order_req_quantity", { required: true })}
                  />
                  {errors.work_order_req_quantity && (
                    <span className="fs-8 text-danger">
                      Work Order Request Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    BOM<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={bomDropdown?.map((api) => {
                          return { value: api.id, label: api.bom_code };
                        })}
                        isClearable={true}
                        id="select-forms-bom_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="bom_id"
                    control={control}
                  />
                  {errors.bom_id && (
                    <span className="fs-8 text-danger">
                      BOM is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work Order<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={workOrderDropdown?.map((api) => {
                          return { value: api.id, label: api.wo_order };
                        })}
                        isClearable={true}
                        id="select-forms-work_order_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="work_order_id"
                    control={control}
                  />
                  {errors.work_order_id && (
                    <span className="fs-8 text-danger">Work Order is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                  Supplier Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={supplierDropdown?.map((api) => {
                          return { value: api.id, label: api.supplier_name };
                        })}
                        isClearable={true}
                        id="select-forms-supplier_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="supplier_id"
                    control={control}
                  />
                  {errors.supplier_id && (
                    <span className="fs-8 text-danger">
                      Supplier Name is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-bo-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-bo"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBO;
