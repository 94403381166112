import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'

const Base = () => {
  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- sidebar @s --> */}
          <Sidebar />
          {/* <!-- sidebar @e --> */}
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap ">
            {/* <!-- main header @s --> */}
            <Header />
            {/* <!-- main header @e --> */}
            {/* <!-- content @s --> */}
            <Content />
            {/* <!-- content @e --> */}
            {/* <!-- footer @s --> */}
            <Footer />
            {/* <!-- footer @e --> */}
          </div>
          {/* <!-- wrap @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
    </>
  )
}

export default Base