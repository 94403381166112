import axios from "axios";
import React, { useState, createContext, useEffect } from "react";
import UseAPI from "../Token/token_refresh";
import mock_data from '../Data/MOCK_DATA.json'

export const GenericContext = createContext(null);

function GenericContextProvider({ children }) {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  console.log(env_url);

  const [isGenericLoading, setIsGenericLoading] = useState(false);
  const [productDropdown, setProductDropdown] = useState([]);
  const [clientDropdown, setClientDropdown] = useState([]);
  const [typeMasterCategoryDropdown, setTypeMasterCategoryDropdown] = useState(
    []
  );
  const [user, setUser] = useState([]);
  
  const [itemDropdown, setItemDropdown ] = useState([]);
  const [jobCardDropdown, setJobCardDropdown ] = useState([]);
  const [supplierDropdown, setSupplierDropdown] = useState(mock_data.supplier[0].results.data);
  console.log(supplierDropdown)
  const [ salesOrderDropdown, setSalesOrderDropdown ] = useState([])
  const [ purchaseOrderDropdown, setPurchaseOrderDropdown ] = useState([])
  const [ requisitionDropdown, setRequisitionDropdown ] = useState([])
  const [ bomDropdown, setBomDropdown ] = useState([])
  const [ workOrderDropdown, setWorkOrderDropdown ] = useState([])

  const [rawPrivilegeDropdown, setRawPrivilegeDropdown] = useState([]);

  const [citiesDropdown, setCitiesDropdown ] = useState([])

  const getGenericData = async () => {
    setIsGenericLoading(true);
    let url = env_url + `/api/generic/`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await api(config);
      let response = res.data;
      console.log(res.data);
      setIsGenericLoading(false);
      setProductDropdown(res.data.item.filter(api => api.category === "FG"));
      setClientDropdown(res.data.client);
      setTypeMasterCategoryDropdown(res.data.category);
      setUser(res.data.user);
      setRawPrivilegeDropdown(res.data.privilege.map((api) => {
        return {label: api.privilege_name, value: api.id}
      }))
      setItemDropdown(res.data.item.filter(api => api.category !== "FG"))


      // setSupplierDropdown(res.data.supplier);
      setSalesOrderDropdown(res.data.salesorder)
      setJobCardDropdown(res.data.jobcard)
      setPurchaseOrderDropdown(res.data.purchaseorder)
      setRequisitionDropdown(res.data.requisition)
      setBomDropdown(res.data.bom)
      setWorkOrderDropdown(res.data.workorder)
    } catch (error) {
      console.log(error);
      setIsGenericLoading(false);
    }
  };

  const getCitiesData = async () => {
    let data = JSON.stringify({
      country: "India",
      state: "Gujarat",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://countriesnow.space/api/v0.1/countries/state/cities",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCitiesDropdown(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGenericData();
    getCitiesData();
  }, []);

  return (
    <div>
      <GenericContext.Provider
        value={{
          isGenericLoading,
          productDropdown,
          clientDropdown,
          typeMasterCategoryDropdown,
          supplierDropdown,
          user,
          citiesDropdown,
          salesOrderDropdown,
          itemDropdown,
          jobCardDropdown,
          purchaseOrderDropdown,
          requisitionDropdown,
          bomDropdown,
          workOrderDropdown,

          rawPrivilegeDropdown,
          getGenericData
        }}
      >
        {children}
      </GenericContext.Provider>
    </div>
  );
}

export default GenericContextProvider;
