import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingContext } from "../../../Context/SettingContext";
import UseAPI from "../../../Token/token_refresh"
import secureLocalStorage from "react-secure-storage";

const EditTypeMasterCategory = () => {
  const userID = secureLocalStorage.getItem("userID")
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const { getTypeMasterCategoryData } = useContext(SettingContext);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var type_master_category = JSON.stringify({
      category_name: data.category_name,
      category_value: data.category_value,
      description: data.description,
      // updated_by: userID,
    });

    const url = env_url + `/api/type-master-category/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: type_master_category,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Type Master Category Updated!",
          text: "The type master category details have been successfully updated.",
          icon: "success",
        });
        setSubmitLoading(false);
        getTypeMasterCategoryData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Updating Type Master Category",
          text: "There was an issue updating the type master category details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editTypeMasterCategory = location.state.editpost;
    // defaultValues is a react form hook.
    if (editTypeMasterCategory) {
      var defaultValues = {};
      defaultValues.category_name = editTypeMasterCategory.category_name;
      defaultValues.category_value = editTypeMasterCategory.category_value;
      defaultValues.description = editTypeMasterCategory.description;

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Type Master Category </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Category Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter type master category display name"
                    id="text-forms-category_name"
                    {...register("category_name", { required: true })}
                  />
                  {errors.category_name && (
                    <span className="fs-8 text-danger">
                      Type Master Category Display Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Category Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter type master category name"
                    id="email-forms-category_value"
                    {...register("category_value", { required: true })}
                  />
                  {errors.category_value && (
                    <span className="fs-8 text-danger">
                      Type Master category name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Description<span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Description for type master category"
                    id="textarea-forms-description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="fs-8 text-danger">
                      Description is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-type-master-category-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-type-master-category"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTypeMasterCategory;
