import axios from "axios";
import React, { useState, createContext } from "react";
import UseAPI from "../Token/token_refresh";
import mock_data from "../Data/MOCK_DATA.json"

export const SalesOrderContext = createContext(null);

function SalesOrderContextProvider({ children }) {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  console.log(env_url)
  
  const [isSalesOrderLoading, setIsSalesOrderLoading] = useState(false);
  const [rawSalesOrderDetails, setRawSalesOrderDetails] = useState(mock_data.sales_order[0].results.data);
  const [salesOrderTotalCount, setSalesOrderTotalCount] = useState(null);

  const getSalesOrderData = async (page, entries, search, filter, sort) => {
    setIsSalesOrderLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append("event", search);
    if (sort?.key) queryParams.append("sortkey", sort.key);
    if (sort?.direction) queryParams.append("sortOrder", sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(","));
        }
      });
    }

    let url = env_url + `/api/sales-order/?entries=${entries}&page_no=${page}`;

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data);
      setIsSalesOrderLoading(false);
      if (response.length !== 0) {
        setRawSalesOrderDetails(res.data.results?.data);
      } else {
        setRawSalesOrderDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setSalesOrderTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsSalesOrderLoading(false);
    }
  };

  return (
    <div>
      <SalesOrderContext.Provider
        value={{
          isSalesOrderLoading,
          rawSalesOrderDetails,
          salesOrderTotalCount,
          getSalesOrderData,
        }}
      >
        {children}
      </SalesOrderContext.Provider>
    </div>
  );
}

export default SalesOrderContextProvider;
