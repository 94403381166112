import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UseAPI from "../../Token/token_refresh";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { WorkOrderContext } from "../../Context/WorkOrderContext";
import { GenericContext } from "../../Context/GenericContext";

const CreateWorkOrder = () => {
  const userID = secureLocalStorage.getItem("userID");
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { getWorkOrderData } = useContext(WorkOrderContext);
  const { salesOrderDropdown } = useContext(GenericContext)

  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var work_order = JSON.stringify({
      sales_order: data.sales_order.value,
      prod_start_date: data.prod_start_date,
      dispatch_date: data.dispatch_date,
      target_quantity: data.target_quantity,
      // created_by: userID,
      // updated_by: userID,
    });

    const url = env_url + "/api/work-order/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: work_order,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Work Order Added!",
          text: "The work order details have been successfully added.",
          icon: "success",
        });
        setSubmitLoading(false);
        getWorkOrderData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Adding Work Order",
          text: "There was an issue adding the work order details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Work Order </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sales Order Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={salesOrderDropdown.map((api) => {
                          return { label: api.so_number, value: api.id };
                        })}
                        isClearable={true}
                        id="select-forms-sales-order"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="salesorder"
                    control={control}
                  />
                  {errors.salesorder && (
                    <span className="fs-8 text-danger">Sales Order is Required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Production Start Date<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="email-forms-prod_start_date"
                    {...register("prod_start_date", { required: true })}
                  />
                  {errors.prod_start_date && (
                    <span className="fs-8 text-danger">
                      Production start date is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                  Dispatch Date.<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="email-forms-dispatch_date"
                    {...register("dispatch_date", { required: true })}
                  />
                  {errors.dispatch_date && (
                    <span className="fs-8 text-danger">Dispatch date is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Target Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter your target quantity"
                    id="input-forms-email"
                    {...register("target_quantity", { required: true })}
                  />
                  {errors.target_quantity && (
                    <span className="fs-8 text-danger">
                      Target Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-work-order-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-work-order"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateWorkOrder;
