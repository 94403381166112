import axios from "axios";
import React, { useContext, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { LogoutContext } from "../Context/LogoutContext";
import secureLocalStorage from "react-secure-storage";
import UseAPI from "../Token/token_refresh";

const Login = (props) => {
  const userID = secureLocalStorage.getItem("userID")
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const {setUser} = useContext(LogoutContext)
  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Password show/hide state to handle
  const [showPassword, setShowPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = (data) => {
    let user = JSON.stringify({
      username: data.username,
      password: data.password,
      created_by : 1,
      updated_by : 1
    });

    const url = env_url + "/authentication/login-api/";
    console.log(url);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    setSubmitLoading(true);
    api
      .request(config)
      .then((response) => {
        console.log(response);
        const user_data = response.data.data;
        if (response.status == 200) {
          props.loginHandler(user_data);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error.response);
        Swal.fire({
          title: "Login Failed!",
          text: "There was an error logging into your account. Please try again.",
          icon: "error",
        });
      });
  };

  const loginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      const user = codeResponse;
      checkGoogleAuthentication(user);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const checkGoogleAuthentication = (user) => {
    console.log(user);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          let profile = res.data;
          console.log(profile);

          let user = JSON.stringify({
            email: profile.email,
            first_name: profile.given_name,
            last_name: profile.family_name,
            is_google_login :true,
            created_by : 1,
            updated_by : 1
          });

          const url = env_url + "/authentication/login-api/";
          console.log(url);

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: url,
            headers: {
              "Content-Type": "application/json",
            },
            data: user,
          };

          setSubmitLoading(true);
          api
            .request(config)
            .then((response) => {
              const userData = response.data.data[0];
              console.log(userData)
              console.log(response)
              const loginStatus = response.status;
              if (loginStatus === 200) {
                setSubmitLoading(false);
                props.loginHandler(userData);
              }
            })
            .catch((error) => {
              console.log(error.response);
              setSubmitLoading(false)
            });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <a href="javacsript:void(0)" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src="./assets/images/gev-logo-dark.png"
                      srcSet="./assets/images/gev-logo-dark.png 2x"
                      alt="gev-logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src="./assets/images/gev-logo-dark.png"
                      srcSet="./assets/images/gev-logo-dark.png 2x"
                      alt="gev-logo"
                    />
                  </a>
                </div>
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title text-center">Login</h4>
                        <div className="nk-block-des">
                          <p>
                            Access the Green Edge Venture Application using your
                            username and password.
                          </p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Username
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="text-forms-text"
                            placeholder="Enter your username"
                            {...register("username", {
                              required: "Username is required",
                              // validate: (value) =>
                              //   value === value.toLowerCase() ||
                              //   "Username should only contain lowercase letters",
                            })}
                          />
                        </div>
                        {errors.username && (
                          <span className="fs-8 text-danger">
                            {errors?.username?.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label
                            className="form-label"
                            htmlFor="password-forms-password"
                          >
                            Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="javascript:void(0)"
                            onClick={() => setShowPassword(!showPassword)}
                            className={`${
                              showPassword === true
                                ? "form-icon form-icon-right passcode-switch lg is-shown"
                                : "form-icon form-icon-right passcode-switch lg"
                            }`}
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type={`${
                              showPassword === true ? "text" : "password"
                            }`}
                            className={`${
                              showPassword === true
                                ? "form-control form-control-lg valid is-shown"
                                : "form-control form-control-lg"
                            }`}
                            id="password-forms-password"
                            placeholder="Enter your passcode"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                        </div>
                        {errors.password && (
                          <span className="fs-8 text-danger">
                            {errors?.password?.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        {submitLoading === true ? (
                          <button
                            id="button-loading"
                            className="btn btn-lg btn-primary btn-block"
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary btn-block"
                            id="submit-button-login"
                            atl="signin"
                          >
                            Login
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="text-center pt-4 pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <ul className="nav justify-center gx-4">
                      <li className="nav-item">
                        <a
                          className="btn btn-primary"
                          href="#"
                          onClick={() => loginHandler()}
                          id="button-google-authentication"
                        >
                          Sign in with Google
                          <em className="ni ni-google ms-1"></em>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
    </>
  );
};

export default Login;
