import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UseAPI from "../../Token/token_refresh"
import Swal from "sweetalert2";
import { SalesOrderContext } from "../../Context/SalesOrderContext";

const columns = [
  {
    accessor: "so_number",
    header: "Sales Order number",
  },
  {
    accessor: "client",
    header: "Client Name",
    cell: (row) => <>{row?.client?.client_name}</>,
  },
  {
    accessor: "product",
    header: "Product Name",
    cell: (row) => <>{row?.product?.item_name}</>,
  },
  {
    accessor: "product",
    header: "Product ID",
    cell: (row) => <>{row?.product?.item_code}</>,
  },
  {
    accessor: "target_date",
    header: "Target Date",
  },
  {
    accessor: "dispatch_location",
    header: "Dispatch Location",
  },
  {
    accessor: "quantity",
    header: "Quantity",
  },
  {
    accessor: "to_be_produced",
    header: "To Be Produced",
  },
  {
    accessor: "stock_allotted",
    header: "Allotted Stock",
  },
  {
    accessor: "button",
    header: "Work Order",
    cell: (row) => <NavLink className="btn btn-outline-primary" to="/sales-work-order" state={{ editpost: row }}>Work</NavLink>,
  },
];

const SalesOrder = () => {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const [salesOrderEntries, setSalesOrderEntries] = useState(5);
  const [salesOrderPageIndex, setSalesOrderPageIndex] = useState(0);

  const {
    isSalesOrderLoading,
    rawSalesOrderDetails,
    salesOrderTotalCount,
    getSalesOrderData,
  } = useContext(SalesOrderContext);

  const handleGlobalFilterChange = (value) => {
    console.log(value);
    getSalesOrderData(0, salesOrderEntries, value);
  };

  const handlePagination = (value) => {
    console.log(value);
    getSalesOrderData(value.pageIndex, value.pageSize);
    setSalesOrderPageIndex(value.pageIndex);
    setSalesOrderEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-sales-order", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSalesOrderEntry(entry_id);
      }
    });
  };

  const deleteSalesOrderEntry = (id) => {
    const url = env_url + `/api/sales-order/${id}/`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
    };

    api(config).then((response) => {
      console.log(response.data);
      Swal.fire({
        title: "Entry Deleted!",
        text: "The selected entry have been successfully deleted.",
        icon: "success"
      });
      getSalesOrderData(0, 5)
    }).catch((error) => {
      console.log(error);
      Swal.fire({
        title: "Entry not Deleted!",
        text: "The selected entry has not been successfully deleted.",
        icon: "error"
      });
    });
  };

  useEffect(() => {
    getSalesOrderData(salesOrderPageIndex,salesOrderEntries);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Sales Order</h3>
            {isSalesOrderLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content">
            <NavLink
              to="/create-sales-order"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-sales-order"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-sales-order"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-sales-order"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create Sales Order</span>
            </NavLink>
          </div>
        </div>
      </div>

      <Table
        data={rawSalesOrderDetails}
        columns={columns}
        pagination
        pageIndex={salesOrderPageIndex}
        pageSize={salesOrderEntries}
        rowCount={salesOrderTotalCount}
        globalSearch
        editRows
        deleteRows
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default SalesOrder;
