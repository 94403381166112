import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { SettingContext } from "../../../Context/SettingContext";
import UseAPI from "../../../Token/token_refresh";
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../../Context/GenericContext";

const CreateTypeMaster = () => {
  const userID = secureLocalStorage.getItem("userID");
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { getTypeMasterData } = useContext(SettingContext);
  const { isGenericLoading, typeMasterCategoryDropdown } = useContext(GenericContext);

  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var type_master = JSON.stringify({
      name: data.name,
      value: data.value,
      category_id: data.category.value,
      description: data.description,
      // created_by: userID,
      // updated_by: userID,
    });

    const url = env_url + "/api/type-master/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: type_master,
    };

    setSubmitLoading(true);
    api(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Type Master Added!",
          text: "The type master details have been successfully added.",
          icon: "success",
        });
        setSubmitLoading(false);
        getTypeMasterData(0, 5);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Adding Type Master",
          text: "There was an issue adding the type master details. Please try again.",
          icon: "error",
        });
        setSubmitLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Type Master </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Type Master Display Name"
                    id="text-forms-name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="fs-8 text-danger">
                      Type Master Display Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter type master name"
                    id="email-forms-value"
                    {...register("value", { required: true })}
                  />
                  {errors.value && (
                    <span className="fs-8 text-danger">
                      Type Master Value is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Category
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={typeMasterCategoryDropdown?.map((info) => ({
                          value: info.id,
                          label: info.category_name,
                        }))}
                        isLoading={isGenericLoading}
                        isClearable={true}
                        id="select-forms-typemaster-category"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Type Master Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Description<span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Description for type master"
                    id="textarea-forms-description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="fs-8 text-danger">
                      Description is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-typemaster-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-typemaster"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTypeMaster;
