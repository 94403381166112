import React from 'react'
import { useNavigate } from 'react-router-dom'

const Error = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block nk-block-middle wide-xs mx-auto">
        <div className="nk-block-content nk-error-ld text-center">
          <h3 className="nk-error-head">404</h3>
          <h4 className="nk-error-title">Oops! Something went wrong</h4>
          <p className="nk-error-text">We’re sorry, but the page you’re looking for doesn’t exist or an unexpected error has occurred.</p>
          <ul className="list" style={{textAlign : "left"}}>
            <li className='list-group-item'>Double-check the URL for any errors.</li>

            <li className='list-group-item'>Go back to the <button className='btn btn-sm btn-light btn-rounded text-primary' onClick={() => navigate(-1)} >Last Page</button></li>

            <li className='list-group-item'>If the problem persists, feel free to contact our support team.</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Error