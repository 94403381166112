import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { SettingContext } from "../../../Context/SettingContext";
import UseAPI from "../../../Token/token_refresh"
import secureLocalStorage from "react-secure-storage";
import { GenericContext } from "../../../Context/GenericContext";

const EditRole = () => {
  const userID = secureLocalStorage.getItem("userID")
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)
  // react Hook form Props
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false)

  const { getGenericData } = useContext(GenericContext)
  const { getRoleData } = useContext(SettingContext)
  const { rawPrivilegeDropdown } = useContext(GenericContext)

  const [selectedPrivilges, setSelectedPrivilges] = useState([]);
  console.log(selectedPrivilges);

  const onChange = (value) => {
    setSelectedPrivilges(value);
  };

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var role = JSON.stringify({
      role_name: data.role_name,
      role_value: data.role_value,
      privilege_id: selectedPrivilges,
      hierarchy: data.hierarchy,
      description: data.description,
      // updated_by: userID,
      });

    const url = env_url + `/api/roles/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: role,
    };

    setSubmitLoading(true)
    api(config).then((response) => {
      console.log(JSON.stringify(response.data));
      Swal.fire({
        title: "Role Updated!",
        text: "The role details have been successfully updated.",
        icon: "success"
      });
      setSubmitLoading(false);
      getRoleData(0,5)
      setTimeout(() => {navigate(-1)}, 2000);
      reset();
    }).catch((error) => {
      Swal.fire({
        title: "Error Updating Role",
        text: "There was an issue updating the role details. Please try again.",
        icon: "error"
      });
      setSubmitLoading(false);
      console.log(error);
    })
  };

  // Pre Population of Particular Product details 
  useEffect(() => {
    const editRole = location.state.editpost;
    // defaultValues is a react form hook. 
    if (editRole) {
      var defaultValues = {};
      defaultValues.role_name = editRole.role_name;
      defaultValues.role_value = editRole.role_value;
      defaultValues.description = editRole.description;
      defaultValues.hierarchy = editRole.hierarchy;
      const selectedPri = editRole.privilege.map((pr) => pr.id);
      setSelectedPrivilges(selectedPri);

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
      getGenericData()
    },[])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Role </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Role Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter role display name"
                    id="text-forms-role_name"
                    {...register("role_name", { required: true })}
                  />
                  {errors.role_name && (
                    <span className="fs-8 text-danger">
                      Role Display Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Role name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter role name"
                    id="email-forms-role_value"
                    {...register("role_value", { required: true })}
                  />
                  {errors.role_value && (
                    <span className="fs-8 text-danger">
                      Role Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Assign Privileges<span className="fs-8 text-danger"> *</span>
                  </label>
                  <DualListBox
                    id="dualbox-list-privilege"
                    canFilter
                    filterPlaceholder="Search using privilege"
                    options={rawPrivilegeDropdown}
                    selected={selectedPrivilges}
                    onChange={onChange}
                    required
                  />
                  <span className="fs-6 text-info fw-medium">
                    Double-click on the privilege to assign it.
                  </span>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-2">
                    <label className="form-label">
                      Hierarchy <span className="fs-8 text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter hierarchy number for role"
                      id="textarea-forms-hierarchy"
                      {...register("hierarchy", { required: true })}
                    />
                    {errors.hierarchy && (
                      <span className="fs-8 text-danger">
                        Hierarchy is required
                      </span>
                    )}
                  </div>
                  <div>
                    <label className="form-label">
                      Description<span className="fs-8 text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Enter description"
                      id="textarea-forms-description"
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <span className="fs-8 text-danger">
                        Description is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {submitLoading ? (
                      <button
                        type="button"
                        id="button-role-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-role"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
