import axios from "axios";
import React, { useState, createContext } from "react";
import UseAPI from "../Token/token_refresh"
import mock_data from "../Data/MOCK_DATA.json"

export const SettingContext = createContext(null);

function SettingContextProvider({ children }) {
  const api = UseAPI();
  const env_url = process.env.REACT_APP_API_URL
  console.log(env_url)

  // Role Data stores in "rawRoleDetails".
  const [isRoleLoading, setIsRoleLoading] = useState(false);
  const [rawRoleDetails, setRawRoleDetails] = useState([]);
  const [roleTotalCount, setRoleTotalCount] = useState(null);

  // Privileges Data stores in "rawPrivilegesDetails".
  const [isPrivilegesLoading, setIsPrivilegesLoading] = useState(false);
  const [rawPrivilegesDetails, setRawPrivilegesDetails] = useState([]);
  const [privilegesTotalCount, setPrivilegesTotalCount] = useState(null);

  // TypeMaster Data stores in "rawTypeMasterDetails".
  const [isTypeMasterLoading, setIsTypeMasterLoading] = useState(false);
  const [rawTypeMasterDetails, setRawTypeMasterDetails] = useState([]);
  const [typeMasterTotalCount, setTypeMasterTotalCount] = useState(null);

  // TypeMasterCategory Data stores in "rawTypeMasterCategoryDetails".
  const [isTypeMasterCategoryLoading, setIsTypeMasterCategoryLoading] = useState(false);
  const [rawTypeMasterCategoryDetails, setRawTypeMasterCategoryDetails] = useState([]);
  const [typeMasterCategoryTotalCount, setTypeMasterCategoryTotalCount] = useState(null);

  const getRoleData = async (page, entries, search, filter, sort) => {
    setIsRoleLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append('event', search);
    if (sort?.key) queryParams.append('sortkey', sort.key);
    if (sort?.direction) queryParams.append('sortOrder', sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(','));
        }
      });
    }

    let url = env_url + `/api/roles/?entries=${entries}&page_no=${page}`

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data)
      setIsRoleLoading(false);
      if (response.length !== 0) {
        setRawRoleDetails(res.data.results?.data);
      } else {
        setRawRoleDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setRoleTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsRoleLoading(false);
    }
  };

  const getPrivilegesData = async (page, entries, search, filter, sort) => {
    setIsPrivilegesLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append('event', search);
    if (sort?.key) queryParams.append('sortkey', sort.key);
    if (sort?.direction) queryParams.append('sortOrder', sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(','));
        }
      });
    }

    let url = env_url + `/api/privileges/?entries=${entries}&page_no=${page}`

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data)
      setIsPrivilegesLoading(false);
      if (response.length !== 0) {
        setRawPrivilegesDetails(res.data.results?.data);
      } else {
        setRawPrivilegesDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setPrivilegesTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsPrivilegesLoading(false);
    }
  };

  const getTypeMasterData = async (page, entries, search, filter, sort) => {
    setIsTypeMasterLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append('event', search);
    if (sort?.key) queryParams.append('sortkey', sort.key);
    if (sort?.direction) queryParams.append('sortOrder', sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(','));
        }
      });
    }

    let url = env_url + `/api/type-master/?entries=${entries}&page_no=${page}`

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data)
      setIsTypeMasterLoading(false);
      if (response.length !== 0) {
        setRawTypeMasterDetails(res.data.results?.data);
      } else {
        setRawTypeMasterDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setTypeMasterTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsTypeMasterLoading(false);
    }
  };

  const getTypeMasterCategoryData = async (page, entries, search, filter, sort) => {
    setIsTypeMasterCategoryLoading(true);

    let queryParams = new URLSearchParams();

    // Conditionally add query parameters
    if (search) queryParams.append('event', search);
    if (sort?.key) queryParams.append('sortkey', sort.key);
    if (sort?.direction) queryParams.append('sortOrder', sort.direction);

    // Add filters dynamically
    if (filter) {
      // Dynamically add selected filters to the query params using filter_key=value format
      Object.keys(filter).forEach((filterKey) => {
        if (filter[filterKey].length > 0) {
          // Join multiple values with commas (or customize this based on your API's expected format)
          queryParams.append(`${filterKey}`, filter[filterKey].join(','));
        }
      });
    }

    let url = env_url + `/api/type-master-category/?entries=${entries}&page_no=${page}`

    const queryString = queryParams.toString();
    if (queryString) {
      url += `&${queryString}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url
    };

    try {
      const res = await api(config);
      let response = res.data.results?.data;
      console.log(res.data)
      setIsTypeMasterCategoryLoading(false);
      if (response.length !== 0) {
        setRawTypeMasterCategoryDetails(res.data.results?.data);
      } else {
        setRawTypeMasterCategoryDetails(res.data.results?.data);
      }
      const total = res.data.count;
      setTypeMasterCategoryTotalCount(total);
    } catch (error) {
      console.log(error);
      setIsTypeMasterCategoryLoading(false);
    }
  };

  return (
    <div>
      <SettingContext.Provider
        value={{
          isRoleLoading,
          rawRoleDetails,
          getRoleData,
          roleTotalCount,

          isPrivilegesLoading,
          rawPrivilegesDetails,
          getPrivilegesData,
          privilegesTotalCount,

          isTypeMasterLoading,
          rawTypeMasterDetails,
          getTypeMasterData,
          typeMasterTotalCount,

          isTypeMasterCategoryLoading,
          rawTypeMasterCategoryDetails,
          getTypeMasterCategoryData,
          typeMasterCategoryTotalCount,
        }}
      >
        {children}
      </SettingContext.Provider>
    </div>
  );
}

export default SettingContextProvider;
